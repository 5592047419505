import React, {FC, Suspense, useEffect, useState} from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import {SWRConfig} from "swr";
import {fetcher} from "@shared/fetcher";
import "@entities/localization";
import {router} from "@routes/router";
import {RouterProvider} from "react-router";
import "animate.css/animate.min.css";
import {initLocales} from "@entities/localization";
import {FullPageLoader} from "@shared/ui/full-page-loader";
import {ErrorBoundary} from "@features/error-pages/error-boundary";
import {ToastContainer, ToastPosition} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {ConfirmAction} from "@features/confirm-action/confirm-action";
import {StorageManager} from "@features/storage-manager/storage-manager";
import {localStorageCacheProvider} from "@shared/lib/cacheProvider";
import {useTheme} from "@features/theme/hooks";
import 'simplebar-react/dist/simplebar.min.css';
import {SECOND} from "@shared/lib/timeUnits";

import("es7-object-polyfill");
import('object.fromentries')
// @ts-ignore
if (!Object.fromEntries) {
    Object.fromEntries = function (entries: any) {
        if (!entries || !entries[Symbol.iterator]) {
            throw new TypeError('Object.fromEntries requires a single iterable argument');
        }

        let obj = {};

        for (let entry of entries) {
            const [key, value] = entry;
            obj[key] = value;
        }

        return obj;
    };
}
const target = document.getElementById("root");
if (!target) throw new Error("Root element not found");

const Main: FC = () => {
    const [toastPosition] = useState<ToastPosition>(StorageManager.getFromLocal('toast-position', 'top-right'))
    useTheme()
    useEffect(() => {
        initLocales();
        window.dispatchEvent(new Event("load"));
        document.dispatchEvent(new Event("load"));

        if(process.env.REACT_APP_COMMIT_HASH && localStorage.getItem('version') !== process.env.REACT_APP_COMMIT_HASH){
            if(typeof caches !== 'undefined'){
                caches.keys().then((keys) => {
                    return Promise.all(keys.map((key) => caches.delete(key)));
                }).then(() => {
                    localStorage.setItem('version', String(process.env.REACT_APP_COMMIT_HASH));
                }).catch(() => null)
            }
        }

    }, []);
    return <>
        <ToastContainer
            stacked
            className={"toasts text-lg"}
            pauseOnHover
            draggable
            position={toastPosition}
            autoClose={5e3}
        />
        <ConfirmAction/>
    </>;
};

const root = ReactDOM.createRoot(target);
root.render(
    <React.StrictMode>
        <ErrorBoundary>
            <SWRConfig
                value={{
                    dedupingInterval: 10 * SECOND,
                    revalidateOnFocus: true,
                    revalidateOnMount: true,
                    refreshWhenHidden: true,
                    keepPreviousData: true,
                    fetcher: fetcher,
                    provider: localStorageCacheProvider,
                }}
            >
                <Main/>
                <Suspense fallback={<FullPageLoader/>}>
                    <RouterProvider router={router}/>
                </Suspense>
            </SWRConfig>
        </ErrorBoundary>
    </React.StrictMode>,
);

serviceWorkerRegistration.register();